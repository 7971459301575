import React, { useState, useCallback, useRef, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Autosuggest from "react-autosuggest";
import FetchFights from "./components/FetchFights";
import BossSelection from "./components/BossSelection";
import PlayerTable from "./components/PlayerTable";
import Summary from "./components/Summary";
import Buffs from "./components/Buffs";
import { searchCharacter, searchGuild } from "./components/warcraftLogsApi";
import NewPage from "./components/NewPage";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

const US_REALMS = [
  "Angerforge",
  "Arugal",
  "Ashkandi",
  "Atiesh",
  "Azuresong",
  "Benediction",
  "Bloodsail Buccaneers",
  "Earthfury",
  "Eranikus",
  "Faerlina",
  "Grobbulus",
  "Maladath",
  "Mankrik",
  "Myzrael",
  "Old Blanchy",
  "Pagle",
  "Remulos",
  "Skyfury",
  "Sulfuras",
  "Westfall",
  "Whitemane",
  "Windseeker",
  "Yojamba",
];

const EU_REALMS = [
  "Firemaw",
  "Flamegor",
  "Gehennas",
  "Golemagg",
  "Mirage Raceway",
  "Auberdine",
  "Everlook",
  "Lakeshire",
  "Pyrewood Village",
  "Venoxis",
  "Mandokir",
  "Sulfuron",
  "Chromie",
  "Hydraxian Waterlords",
  "Patchwerk",
  "Amnennar",
  "Ashbringer",
  "Earthshaker",
  "Giantstalker",
  "Jin'do",
  "Mograine",
  "Nethergarde Keep",
  "Razorfen",
  "Thekal",
  "Transcendence",
];

const PAGLE_GUILDS = [
  "CB",
  "pm",
  "Melee Mechanics",
  "Ultimate",
  "we pulled",
  "Precision",
  "Ahead of the Curve",
  "RATS",
  "Honør",
  "Relax",
  "Inept",
  "Cope",
  "Grit",
  "Divinity Classic",
  "The Mediocre Men",
  "CASHBACK",
  "NightFallen",
  "Pure",
  "Breakfast Club",
  "Just Outgear It",
  "Super Best Friends Club",
  "Wipe Protection",
  "Dalaran Degens",
  "Evolutionary",
  "Divine Brutality",
  "NERV",
  "Chaotic Neutral",
  "Lizard Milk",
  "Locked Out GDKP's",
  "CBGDKP",
  "Criticism",
  "Dot's Pagle GDKPs",
  "whatever",
  "Elitists",
  "Goldbound GDKP",
  "MOUSSE",
  "Pugging Parsers",
  "SMH",
  "Cope GDKP",
  "Apocalypse Meow",
  "Shameless",
  "NOTHING OF VALUE",
  "swamp",
  "Best In Slot",
  "Spectacular Drip",
  "In-Debt GDKP",
  "Dangerous To Go Alone",
  "Argos",
  "Dogeville",
  "Funkin GDKP",
  "Honor",
];

function App() {
  const [reportCode, setReportCode] = useState("");
  const [bosses, setBosses] = useState([]);
  const [selectedBoss, setSelectedBoss] = useState(null);
  const [players, setPlayers] = useState([]);
  const [showBossSelection, setShowBossSelection] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [visiblePlayer, setVisiblePlayer] = useState(null);
  const [searchType, setSearchType] = useState("character");
  const [region, setRegion] = useState("US");
  const [realm, setRealm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [scrollToBossSelection, setScrollToBossSelection] = useState(false);
  const [scrollToDetails, setScrollToDetails] = useState(false);
  const [showFixedText, setShowFixedText] = useState(true); // New state for fixed text visibility
  const fetchFightsRef = useRef(null);
  const bossSelectionRef = useRef(null);
  const detailsRef = useRef(null);

  const handleReportCodeChange = useCallback((e) => {
    setReportCode(e.target.value);
  }, []);

  const handleFetchFights = useCallback((fights) => {
    if (!fights || fights.length === 0) {
      console.error("No fights found");
      return;
    }

    const formattedFights = fights.map((fight) => ({
      id: fight.id,
      name: fight.name,
      zoneName: fight.zoneName,
      zoneDifficulty: fight.zoneDifficulty,
      start_time: fight.start_time,
      end_time: fight.end_time,
    }));
    setBosses(formattedFights);
    setShowBossSelection(true);
    setScrollToBossSelection(true);
    setShowFixedText(false); // Hide fixed text after fetching fights
  }, []);

  const handleSelectBoss = useCallback((boss) => {
    setSelectedBoss(boss);
    setShowDetails(true);
    setScrollToDetails(true);
  }, []);

  const handleFetchPlayers = useCallback((players) => {
    if (!players || players.length === 0) {
      console.error("No players found");
      return;
    }
    console.log("Fetched Players:", players);
    setPlayers(players);
  }, []);

  const handlePlayerClick = useCallback(
    (playerName) => {
      setVisiblePlayer(visiblePlayer === playerName ? null : playerName);
    },
    [visiblePlayer],
  );

  const handleSearch = async () => {
    try {
      if (searchType === "character") {
        const data = await searchCharacter(region, realm, searchTerm);
        // Process the data as needed and update the state
      } else {
        const data = await searchGuild(region, realm, searchTerm);
        if (data && data.length > 0) {
          const mostRecentReport = data[0];
          console.log("Most Recent Report:", mostRecentReport);
          if (mostRecentReport.id) {
            setReportCode(mostRecentReport.id);
            setTimeout(fetchFights, 0); // Simulate pressing the fetch fights button
          } else {
            console.error("Invalid report data", mostRecentReport);
          }
        } else {
          console.error("No reports found for the guild");
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchFights = () => {
    if (fetchFightsRef.current) {
      fetchFightsRef.current.fetchFights();
    }
  };

  useEffect(() => {
    if (scrollToBossSelection && bossSelectionRef.current) {
      bossSelectionRef.current.scrollIntoView({ behavior: "smooth" });
      setScrollToBossSelection(false);
    }
  }, [scrollToBossSelection]);

  useEffect(() => {
    if (scrollToDetails && detailsRef.current) {
      detailsRef.current.scrollIntoView({ behavior: "smooth" });
      setScrollToDetails(false);
    }
  }, [scrollToDetails]);

  const getRealms = (region) => {
    return region === "US" ? US_REALMS : EU_REALMS;
  };

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0
      ? []
      : PAGLE_GUILDS.filter((guild) =>
          guild.toLowerCase().includes(inputValue),
        );
  };

  const getSuggestionValue = (suggestion) => suggestion;

  const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

  const onChange = (event, { newValue }) => {
    setSearchTerm(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <div className="full-screen-container">
                <div id="mainpage" className="section section-1">
                  <div id="report-code-section" className="tophalf text-white">
                    <div className="overlay">
                      <div className="input_container_top">
                        <FetchFights
                          ref={fetchFightsRef}
                          reportCode={reportCode}
                          onReportCodeChange={handleReportCodeChange}
                          onFetchFights={handleFetchFights}
                          onSetReportCode={setReportCode}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    id="search-section"
                    className="bothalf bg-success text-white"
                  >
                    <div className="overlaybot">
                      <div className="input_container_bot">
                        <div className="search-container d-flex flex-row align-items-center">
                          <div className="btn-group-vertical m-2" role="group">
                            <label
                              className={`btn btn-secondary ${searchType === "character" ? "active" : ""}`}
                            >
                              <input
                                type="radio"
                                value="character"
                                checked={searchType === "character"}
                                onChange={(e) => setSearchType(e.target.value)}
                              />
                              Character
                            </label>
                            <label
                              className={`btn btn-secondary ${searchType === "guild" ? "active" : ""}`}
                            >
                              <input
                                type="radio"
                                value="guild"
                                checked={searchType === "guild"}
                                onChange={(e) => setSearchType(e.target.value)}
                              />
                              Guild
                            </label>
                          </div>
                          <div className="btn-group-vertical m-2" role="group">
                            <label
                              className={`btn btn-secondary ${region === "US" ? "active" : ""}`}
                            >
                              <input
                                type="radio"
                                value="US"
                                checked={region === "US"}
                                onChange={(e) => setRegion(e.target.value)}
                              />
                              US
                            </label>
                            <label
                              className={`btn btn-secondary ${region === "EU" ? "active" : ""}`}
                            >
                              <input
                                type="radio"
                                value="EU"
                                checked={region === "EU"}
                                onChange={(e) => setRegion(e.target.value)}
                              />
                              EU
                            </label>
                          </div>
                          <div className="form-group m-2">
                            <select
                              className="form-control"
                              value={realm}
                              onChange={(e) => setRealm(e.target.value)}
                            >
                              <option value="">Select Realm</option>
                              {getRealms(region).map((realmName) => (
                                <option key={realmName} value={realmName}>
                                  {realmName}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="form-group m-2">
                            <Autosuggest
                              suggestions={suggestions}
                              onSuggestionsFetchRequested={
                                onSuggestionsFetchRequested
                              }
                              onSuggestionsClearRequested={
                                onSuggestionsClearRequested
                              }
                              getSuggestionValue={getSuggestionValue}
                              renderSuggestion={renderSuggestion}
                              inputProps={{
                                placeholder:
                                  searchType === "character"
                                    ? "Character Name"
                                    : "Guild Name",
                                value: searchTerm,
                                onChange: onChange,
                              }}
                            />
                          </div>
                          <button
                            className="btn btn-primary m-2"
                            onClick={handleSearch}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {showFixedText && (
                    <>
                      <div className="fixed-text-top" id="ftt">
                        REPORT CODE
                      </div>
                    </>
                  )}

                  <div className="fixed-text-mid">🦊</div>

                  {showFixedText && (
                    <>
                      <div className="fixed-text-bot" id="ftb">
                        SEARCH
                      </div>
                    </>
                  )}
                </div>
                {showBossSelection && (
                  <div
                    className={`section section-4 bg-info text-white`}
                    ref={bossSelectionRef}
                  >
                    <BossSelection
                      bosses={bosses}
                      onSelectBoss={handleSelectBoss}
                    />
                  </div>
                )}
                {showDetails && (
                  <div
                    className={`section section-5 bg-dark text-white`}
                    ref={detailsRef}
                  >
                    <Buffs players={players} />
                    <Summary
                      players={players}
                      onPlayerClick={handlePlayerClick}
                    />
                    <PlayerTable
                      reportCode={reportCode}
                      selectedBoss={selectedBoss}
                      onFetchPlayers={handleFetchPlayers}
                      visiblePlayer={visiblePlayer}
                    />
                  </div>
                )}
              </div>
            }
          />
          <Route
            path="/new-page"
            element={
              <div className="full-screen-container">
                <div className="section section-1 bg-warning text-dark">
                  <h2>Section 1</h2>
                  <p>This is the first section of the new page.</p>
                </div>
                <div className="section section-2 bg-danger text-white">
                  <h2>Section 2</h2>
                  <p>This is the second section of the new page.</p>
                </div>
                <div className="section section-3 bg-light text-dark">
                  <h2>Section 3</h2>
                  <p>This is the third section of the new page.</p>
                </div>
              </div>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
