import React, { useEffect, useState } from "react";
import "./Summary.css";

function Summary({ players, onPlayerClick }) {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    setIsAnimating(true);
    const timer = setTimeout(() => setIsAnimating(false), 1000); // duration of animation

    return () => clearTimeout(timer);
  }, [players]);

  const passedPlayers = players.filter((player) => player.passed);
  const missingEnchantsPlayers = players.filter(
    (player) => player.missingEnchants.length > 0,
  );
  const lowItemLevelPlayers = players.filter(
    (player) => player.lowItemLevel.length > 0,
  );

  const renderPlayers = (players) => {
    return players.length > 0 ? (
      players.map((player) => {
        // Extract the class name from the classSpecIcon string
        const className = player.classSpecIcon.split("_")[0].toLowerCase();
        return (
          <div key={player.name}>
            <button
              onClick={() => onPlayerClick(player.name)}
              className={`btn btn-outline-light player-button ${className}`}
            >
              {player.name}
            </button>
          </div>
        );
      })
    ) : (
      <div>No players</div>
    );
  };

  return (
    <div className={`summary-container ${isAnimating ? "animating" : ""}`}>
      <div className="d-flex">
        <div className="dynamic-column d-flex align-items-stretch">
          <div className="dynamic-content">
            <div className="card-body">
              <h5 className="card-title">PASSED</h5>

              <div className="summary-players ">
                {renderPlayers(passedPlayers)}
              </div>
            </div>
          </div>
        </div>
        <div className="dynamic-column d-flex align-items-stretch">
          <div className="dynamic-content">
            <div className="card-body">
              <h5 className="card-title">MISSING ENCHANTS</h5>

              <div className="summary-players">
                {renderPlayers(missingEnchantsPlayers)}
              </div>
            </div>
          </div>
        </div>
        <div className="dynamic-column d-flex align-items-stretch">
          <div className="dynamic-content">
            <div className="card-body">
              <h5 className="card-title">LOW ITEM LEVEL</h5>

              <div className="summary-players ">
                {renderPlayers(lowItemLevelPlayers)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Summary;
