import React, { useEffect, useState, useCallback } from "react";
import "./PlayerTable.css";

function PlayerTable({
  reportCode,
  selectedBoss,
  onFetchPlayers,
  visiblePlayer,
}) {
  const [players, setPlayers] = useState([]);
  const [cache, setCache] = useState({});
  const [loading, setLoading] = useState(false);

  const processPlayerData = useCallback((playerDetails) => {
    let players = [];
    Object.keys(playerDetails).forEach((role) => {
      playerDetails[role].forEach((player) => {
        const { name, icon, combatantInfo } = player;
        const [clas] = icon.split("-");
        const classSpecIcon = `${clas.toLowerCase()}`;

        const picon = icon.toLowerCase().replace("-", "_");
        const talents = combatantInfo.talents
          .map((talent) => talent.guid)
          .join(" / ");
        const { cellContent, missingEnchants, lowItemLevel } = generateGearCell(
          combatantInfo.gear,
        );

        const warnings = generateWarnings(missingEnchants, lowItemLevel);
        const reportContent =
          warnings.length > 0
            ? warnings.join("<br>")
            : '<span class="pro-player-text">PASSED</span>';

        players.push({
          name,
          classSpecIcon,
          picon,
          talents,
          cellContent,
          reportContent,
          passed: warnings.length === 0,
          missingEnchants,
          lowItemLevel,
        });
      });
    });
    return players;
  }, []);

  useEffect(() => {
    const fetchPlayers = async () => {
      if (!selectedBoss) return;
      setLoading(true);
      try {
        const response = await fetch(
          `https://www.warcraftlogs.com/v1/report/tables/summary/${reportCode}?start=${selectedBoss.start_time}&end=${selectedBoss.end_time}&api_key=7168a1814fba45846c03c50daaec64f4`,
        );
        const data = await response.json();
        const playerDetails = processPlayerData(data.playerDetails);
        setPlayers(playerDetails);
        onFetchPlayers(playerDetails);
      } catch (error) {
        console.error("Error fetching player data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlayers();
  }, [reportCode, selectedBoss, onFetchPlayers, processPlayerData]);

  const generateGearCell = (items) => {
    let cellContent = "";
    const missingEnchants = [];
    const lowItemLevel = [];
    const enchantableSlots = [0, 2, 4, 6, 7, 8, 9, 14, 15, 16];
    const itemSlots = [
      "Head",
      "Neck",
      "Shoulders",
      "Shirt",
      "Chest",
      "Belt",
      "Legs",
      "Boots",
      "Bracers",
      "Gloves",
      "Ring1",
      "Ring2",
      "Trink1",
      "Trink2",
      "Cloak",
      "Mainhand",
      "Offhand",
      "Relic",
      "Tabard",
    ];
    const nonDisplaySlots = [3, 18];

    items.forEach((item, slotIndex) => {
      if (!item || item.id === 0 || nonDisplaySlots.includes(slotIndex)) return;

      const itemId = item.id || "N/A";
      const itemLevel = item.itemLevel || 0;
      const gems = item.gems || [];
      const gemIds = gems.map((gem) => gem.id || "N/A").join(":");
      const enchantId = item.permanentEnchant || "N/A";

      if (enchantId === "N/A" && enchantableSlots.includes(slotIndex)) {
        missingEnchants.push(itemSlots[slotIndex]);
      }
      if (itemLevel < 346 && !nonDisplaySlots.includes(slotIndex)) {
        lowItemLevel.push(itemSlots[slotIndex]);
      }

      const wowheadLink = `https://www.wowhead.com/cata/item=${itemId}?gems=${gemIds}&ench=${enchantId}`;
      const imgUrl = `https://assets.rpglogs.com/img/warcraft/abilities/${item.icon || "inv_misc_questionmark"}`;
      const borderColor = enchantableSlots.includes(slotIndex)
        ? enchantId !== "N/A"
          ? "green"
          : "red"
        : "none";

      cellContent += `
      <a target="_blank" href="${wowheadLink}" rel="item=${itemId}">
      <img src="${imgUrl}" class="gear-or-ability-icon" style="border: 2px solid ${borderColor};">
      </a>
      `;
    });

    return { cellContent, missingEnchants, lowItemLevel };
  };

  const generateWarnings = (missingEnchants, lowItemLevel) => {
    const warnings = [];
    if (missingEnchants.length > 0)
      warnings.push(`Missing Enchant: ${missingEnchants.join(", ")}`);
    if (lowItemLevel.length > 0)
      warnings.push(`Low Item Level: ${lowItemLevel.join(", ")}`);
    return warnings;
  };

  return (
    <div className={""}>
      {loading && <div>Loading...</div>}
      <h3>Player Details</h3>
      {players.map(
        (player) =>
          visiblePlayer === player.name && (
            <div
              key={player.name}
              className={`player-details shadow-${player.classSpecIcon}`}
            >
              <div className="player-info">
                <img
                  src={`/icons/${player.picon}.png`}
                  className="class-spec-icon"
                  alt={player.classSpecIcon}
                />
                <strong>{player.name}</strong>
              </div>
              <div className="player-talents">
                <strong>Talents: </strong>
                {player.talents}
              </div>
              <div
                className="player-gear"
                dangerouslySetInnerHTML={{ __html: player.cellContent }}
              ></div>
              <div
                className="player-report"
                dangerouslySetInnerHTML={{ __html: player.reportContent }}
              ></div>
            </div>
          ),
      )}
    </div>
  );
}

export default PlayerTable;
