import React from "react";
import { ListGroup } from "react-bootstrap";
import "./BossSelection.css";

function BossSelection({ bosses, onSelectBoss }) {
  const handleSelect = (boss) => {
    onSelectBoss(boss);
  };

  const getZoneStyle = (zoneName) => {
    const normalizedZoneName = zoneName.trim().toLowerCase();
    console.log("Normalized Zone Name:", normalizedZoneName); // Debug log

    switch (normalizedZoneName) {
      case "blackwing descent":
        return "list-group-item-danger";
      case "the bastion of twilight":
        return "list-group-item-primary";
      case "throne of the four winds":
        return "list-group-item-warning";
      default:
        return "list-group-item-dark";
    }
  };

  const getDifficultyLabel = (difficulty) => {
    switch (difficulty) {
      case 3:
        return "Normal";
      case 4:
        return "Heroic";
      default:
        return `Difficulty: ${difficulty}`;
    }
  };

  return (
    <ListGroup>
      {bosses.map((boss) => (
        <ListGroup.Item
          className={getZoneStyle(boss.zoneName)}
          key={boss.id}
          action
          onClick={() => handleSelect(boss)}
        >
          {boss.name} ({boss.zoneName},{" "}
          {getDifficultyLabel(boss.zoneDifficulty)}
          {boss.zoneDifficulty === 4 && <span className="skull-icon">💀</span>})
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}

export default BossSelection;
