import React, {
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from "react";

const FetchFights = forwardRef(
  ({ reportCode, onReportCodeChange, onFetchFights, onSetReportCode }, ref) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const extractReportCode = (input) => {
      const urlPattern =
        /https:\/\/classic\.warcraftlogs\.com\/reports\/([a-zA-Z0-9]{16})/;
      const match = input.match(urlPattern);
      if (match) {
        return match[1];
      }
      return input;
    };

    const validateReportCode = (code) => {
      if (code.length !== 16) {
        setError("Report code must be 16 characters long.");
        return false;
      }
      setError("");
      return true;
    };

    const fetchFights = useCallback(async () => {
      const code = extractReportCode(reportCode);
      if (!validateReportCode(code)) {
        return;
      }

      onSetReportCode(code); // Update the report code in the input box

      setLoading(true);
      try {
        const response = await fetch(
          `https://www.warcraftlogs.com:443/v1/report/fights/${code}?api_key=7168a1814fba45846c03c50daaec64f4`,
        );
        const data = await response.json();
        onFetchFights(
          data.fights.filter(
            (fight) => fight.boss !== 0 && fight.kill === true,
          ),
        );
      } catch (error) {
        console.error("Error fetching fights:", error);
      } finally {
        setLoading(false);
      }
    }, [reportCode, onFetchFights, onSetReportCode]);

    useImperativeHandle(ref, () => ({
      fetchFights,
    }));

    return (
      <div>
        <input
          type="text"
          className="form-control form-group m-2 search-container"
          value={reportCode}
          onChange={onReportCodeChange}
          placeholder="Enter Report Code or URL"
        />
        {error && <div className="alert alert-danger m-2">{error}</div>}
        <button
          onClick={fetchFights}
          disabled={loading}
          className="btn btn-primary m-2"
        >
          {loading ? "Fetching..." : "Fetch Fights"}
        </button>
      </div>
    );
  },
);

export default FetchFights;
