// src/components/warcraftLogsApi.js
const API_BASE_URL = "https://classic.warcraftlogs.com:443/v1";
const API_KEY = "7168a1814fba45846c03c50daaec64f4";

export const searchCharacter = async (region, realm, characterName) => {
  const response = await fetch(
    `${API_BASE_URL}/parses/character/${characterName}/${realm}/${region}?api_key=${API_KEY}`,
  );
  const data = await response.json();
  console.log(data);
  return data;
};

export const searchGuild = async (region, realm, guildName) => {
  const response = await fetch(
    `${API_BASE_URL}/reports/guild/${guildName}/${realm}/${region}?api_key=${API_KEY}`,
  );
  const data = await response.json();
  console.log(data);
  return data;
};
