import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import "./Buffs.css";

const buffs = {
  "+10% Melee Speed": [
    { source: "Windfury Totem", class: "shaman", spec: null },
    { source: "Improved Icy Talons", class: "deathknight", spec: "frost" },
    { source: "Hunting Party", class: "hunter", spec: "survival" },
  ],
  "+5% Crit": [
    { source: "Leader of the Pack", class: "druid", spec: "feral" },
    { source: "Elemental Oath", class: "shaman", spec: "elemental" },
    { source: "Honor Among Thieves", class: "rogue", spec: "subtlety" },
    { source: "Rampage", class: "warrior", spec: "fury" },
  ],
  "+10% Attack Power": [
    { source: "Unleashed Rage", class: "shaman", spec: "enhancement" },
    { source: "Trueshot Aura", class: "hunter", spec: "marksman" },
    { source: "Abomination's Might", class: "deathknight", spec: "blood" },
    { source: "Blessing of Might", class: "paladin", spec: null },
  ],
  "+5% Spell Haste": [
    { source: "Moonkin Form", class: "druid", spec: "balance" },
    { source: "Shadow Form", class: "priest", spec: "shadow" },
    { source: "Wrath of Air Totem", class: "shaman", spec: null },
  ],
  "+10% Spell Power": [
    { source: "Demonic Pact", class: "warlock", spec: "demonology" },
    { source: "Totemic Wrath", class: "shaman", spec: "elemental" },
  ],
  "+6% Spell Power": [
    { source: "Arcane Brilliance", class: "mage", spec: null },
    { source: "Flametongue Totem", class: "shaman", spec: null },
  ],
  "+3% to all damage": [
    { source: "Arcane Tactics", class: "mage", spec: "arcane" },
    { source: "Ferocious Inspiration", class: "hunter", spec: "beast mastery" },
    { source: "Communion", class: "paladin", spec: "retribution" },
  ],
  "+5% to all base stats": [
    { source: "Mark of the Wild", class: "druid", spec: null },
    { source: "Blessing of Kings", class: "paladin", spec: null },
  ],
  "+Strength and Agility": [
    { source: "Strength of Earth Totem", class: "shaman", spec: null },
    { source: "Horn of Winter", class: "deathknight", spec: null },
    { source: "Battle Shout", class: "warrior", spec: null },
  ],
  "+Stamina": [
    { source: "PW:Fortitude", class: "priest", spec: null },
    { source: "Blood Pact", class: "warlock", spec: "destruction" },
    { source: "Commanding Shout", class: "warrior", spec: null },
  ],
  "+Max Mana": [
    { source: "Arcane Brilliance", class: "mage", spec: null },
    { source: "Felhunter", class: "warlock", spec: "affliction" },
  ],
  "Combat Resurrection": [
    { source: "Rebirth", class: "druid", spec: null },
    { source: "Soulstone", class: "warlock", spec: null },
    { source: "Reincarnation", class: "shaman", spec: null },
  ],
  "Major Haste buff": [
    { source: "Bloodlust/Heroism", class: "shaman", spec: null },
    { source: "Time Warp", class: "mage", spec: null },
  ],
  "+Armor": [
    { source: "Stoneskin Totem", class: "shaman", spec: null },
    { source: "Devotion Aura", class: "paladin", spec: null },
  ],
  "Spell Pushback Resist": [
    { source: "Concentration Aura", class: "paladin", spec: null },
    { source: "Totem of Tranquil Mind", class: "shaman", spec: null },
  ],
  "Minor mana replenishment": [
    { source: "Vampiric Touch", class: "priest", spec: "shadow" },
    { source: "Enduring Winter", class: "mage", spec: "frost" },
    { source: "Soul Leach", class: "warlock", spec: "destruction" },
    { source: "Revitalize", class: "druid", spec: "restoration" },
    { source: "Communion", class: "paladin", spec: "retribution" },
  ],
  "Major Mana Replenishment": [
    { source: "Innervate", class: "druid", spec: null },
    { source: "Mana Tide Totem", class: "shaman", spec: "restoration" },
    { source: "Hymn of Hope", class: "priest", spec: null },
  ],
  "+MP5": [
    { source: "Felhunter", class: "warlock", spec: "affliction" },
    { source: "Mana Spring Totem", class: "shaman", spec: null },
    { source: "Blessing of Might", class: "paladin", spec: null },
  ],
};

const debuffs = {
  "Armor Debuff": [
    { source: "Expose Armor", class: "rogue", spec: null },
    { source: "Sunder Armor", class: "warrior", spec: null },
    { source: "Faerie Fire/Feral Faerie Fire", class: "druid", spec: null },
  ],
  "+30% bleed damage": [
    { source: "Mangle (Bear)/Mangle (Cat)", class: "druid", spec: "feral" },
    { source: "Hemorrhage", class: "rogue", spec: "subtlety" },
    { source: "Blood Frenzy", class: "warrior", spec: "arms" },
  ],
  "+5% crit for spells only": [
    { source: "Critical Mass", class: "mage", spec: "fire" },
    { source: "Shadow and Flame", class: "warlock", spec: "destruction" },
  ],
  "-20% melee haste debuff": [
    { source: "Infected Wounds", class: "druid", spec: "feral" },
    { source: "Frost Fever", class: "deathknight", spec: null },
    { source: "Judgements of the Just", class: "paladin", spec: "protection" },
    { source: "Thunder Clap", class: "warrior", spec: "protection" },
    { source: "Earth Shock", class: "shaman", spec: null },
  ],
  "+8% magic damage debuff": [
    { source: "Curse of the Elements", class: "warlock", spec: null },
    { source: "Earth and Moon", class: "druid", spec: "balance" },
    { source: "Ebon Plaguebringer", class: "deathknight", spec: "unholy" },
    { source: "Master Poisoner", class: "rogue", spec: "assassination" },
  ],
  "+4% physical damage debuff": [
    { source: "Savage Combat", class: "rogue", spec: "combat" },
    { source: "Brittle Bones", class: "deathknight", spec: "frost" },
    { source: "Blood Frenzy", class: "warrior", spec: "arms" },
  ],
  "Healing Debuff": [
    { source: "Wound Poison", class: "rogue", spec: null },
    { source: "Mortal Strike", class: "warrior", spec: "arms" },
    { source: "Furious Attacks", class: "warrior", spec: "fury" },
    { source: "Widow Venom", class: "hunter", spec: null },
    { source: "Improved Mind Blast", class: "priest", spec: "shadow" },
    {
      source: "Felguard's Legion Strike",
      class: "warlock",
      spec: "demonology",
    },
  ],
  "-10% physical damage output debuff": [
    { source: "Curse of Weakness", class: "warlock", spec: null },
    { source: "Demoralizing Roar", class: "druid", spec: "feral" },
    { source: "Scarlet Fever", class: "deathknight", spec: "blood" },
    { source: "Vindication", class: "paladin", spec: "protection" },
    { source: "Demoralizing Shout", class: "warrior", spec: null },
  ],
  "Cast Speed debuff": [
    { source: "Curse of Tongues", class: "warlock", spec: null },
    { source: "Slow", class: "mage", spec: "arcane" },
    { source: "Mind-Numbing Poison", class: "rogue", spec: null },
    { source: "Necrotic Strike", class: "deathknight", spec: null },
  ],
};

const classColors = {
  warrior: "#C79C6E",
  paladin: "#F58CBA",
  hunter: "#ABD473",
  rogue: "#FFF569",
  priest: "#FFFFFF",
  deathknight: "#C41F3B",
  shaman: "#0070DE",
  mage: "#69CCF0",
  warlock: "#9482C9",
  druid: "#FF7D0A",
  monk: "#00FF96",
  demonhunter: "#A330C9",
};

const getColorByQuantity = (quantity) => {
  if (quantity === 0) {
    return "#ff4c4c"; // red
  } else if (quantity === 1) {
    return "#ffae42"; // orange
  } else {
    return "#4caf50"; // green
  }
};

function Buffs({ players }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const playerBuffs = {};
  const playerDebuffs = {};

  const processBuffsAndDebuffs = (items, playerList) => {
    players.forEach((player) => {
      const { classSpecIcon, spec, name } = player;
      Object.entries(items).forEach(([itemName, sources]) => {
        sources.forEach(({ source, class: itemClass, spec: itemSpec }) => {
          if (
            classSpecIcon === itemClass &&
            (itemSpec === null || itemSpec === spec)
          ) {
            if (!playerList[itemName]) {
              playerList[itemName] = [];
            }
            if (!playerList[itemName][source]) {
              playerList[itemName][source] = [];
            }
            playerList[itemName][source].push({ name, class: classSpecIcon });
          }
        });
      });
    });
  };

  processBuffsAndDebuffs(buffs, playerBuffs);
  processBuffsAndDebuffs(debuffs, playerDebuffs);

  const renderBuffOrDebuff = (items) => (
    <div className="buffs-list">
      {Object.entries(items).map(([itemName, sources]) => {
        const totalProviders = Object.values(sources).flat().length;
        const tooltipContent = Object.entries(sources)
          .map(
            ([source, players]) =>
              `<div>${source}: ${players.map((player) => `<span style="color: ${classColors[player.class]};">${player.name}</span>`).join(", ")}</div>`,
          )
          .join("");

        return (
          <div
            key={itemName}
            className="buff"
            style={{ color: getColorByQuantity(totalProviders) }}
          >
            <span
              data-tooltip-id={`${itemName}`}
              data-tooltip-html={tooltipContent}
            >
              <strong>
                {itemName}: {totalProviders}
              </strong>
            </span>
            <Tooltip
              id={`${itemName}`}
              place="top"
              effect="solid"
              html={true}
            />
          </div>
        );
      })}
    </div>
  );

  const splitBuffs = () => {
    const buffsArray = Object.entries(playerBuffs);
    const midpoint = Math.ceil(buffsArray.length / 2);
    const firstHalf = buffsArray.slice(0, midpoint);
    const secondHalf = buffsArray.slice(midpoint);

    return (
      <div className="buffs-columns">
        <div className="buffs-column">
          {renderBuffOrDebuff(Object.fromEntries(firstHalf))}
        </div>
        <div className="buffs-column">
          {renderBuffOrDebuff(Object.fromEntries(secondHalf))}
        </div>
      </div>
    );
  };

  return (
    <div className="buffs-wrapper">
      <h3 onClick={() => setIsExpanded(!isExpanded)} className="buffs-header">
        Buffs/Debuffs {isExpanded ? "▲" : "▼"}
      </h3>
      {isExpanded && (
        <div className="buffs-container">
          <div className="buffs-debuffs-wrapper">
            <div className="buffs-columns-wrapper">{splitBuffs()}</div>
            <div className="debuffs-wrapper">
              {renderBuffOrDebuff(playerDebuffs)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Buffs;
